import React from 'react'

import Layout from '../components/layout'
import ButtonLink from '../components/ButtonLink'
import { H1 } from '../components/Headlines'
import { Hero } from '../components/Hero'
import Section from '../components/Section'
import { GallerySection } from '../components/GallerySection'
import { TestDrive } from '../components/TestDrive'
import SimpleSlider from '../components/Slider'

import { ImageBox } from '../components/ImageBox'

import DynamicSelect from '../images/gla-suv/highlights/dynamic-select.jpeg'
import Lowering from '../images/gla-suv/highlights/lowering.jpeg'
import SteeringSupport from '../images/gla-suv/highlights/steering-support.jpeg'

import HeaderImage from '../images/gla-suv/header-gla-suv.jpeg'

import InterieurImage from '../images/gla-suv/gallery/interieur-teaser-gla-suv.jpeg'
import ExterieurImage from '../images/gla-suv/gallery/exterieur-teaser-gla-suv.jpeg'

import InterieurImageGallery1 from '../images/gla-suv/gallery/gallery1-interieur-gla-suv.jpeg'
import InterieurImageGallery2 from '../images/gla-suv/gallery/gallery2-interieur-gla-suv.jpeg'
import InterieurImageGallery3 from '../images/gla-suv/gallery/gallery3-interieur-gla-suv.jpeg'
import InterieurImageGallery4 from '../images/gla-suv/gallery/gallery4-interieur-gla-suv.jpeg'
import InterieurImageGallery5 from '../images/gla-suv/gallery/gallery5-interieur-gla-suv.jpeg'
import InterieurImageGallery6 from '../images/gla-suv/gallery/gallery6-interieur-gla-suv.jpeg'

import ExterieurImageGallery1 from '../images/gla-suv/gallery/gallery1-exterieur-gla-suv.jpeg'
import ExterieurImageGallery2 from '../images/gla-suv/gallery/gallery2-exterieur-gla-suv.jpeg'
import ExterieurImageGallery3 from '../images/gla-suv/gallery/gallery3-exterieur-gla-suv.jpeg'
import ExterieurImageGallery4 from '../images/gla-suv/gallery/gallery4-exterieur-gla-suv.jpeg'

const GalleryImagesInterieurSrc = [
  InterieurImageGallery1,
  InterieurImageGallery2,
  InterieurImageGallery3,
  InterieurImageGallery4,
  InterieurImageGallery5,
  InterieurImageGallery6,
]

const GalleryImagesExterieurSrc = [
  ExterieurImageGallery1,
  ExterieurImageGallery2,
  ExterieurImageGallery3,
  ExterieurImageGallery4,
]

const GLASuv = () => (
  <Layout>
    <Hero bgImage={HeaderImage} />
    <Section align="center">
      <H1>Progressiv beim Design souverän im Alltag - die GLA-Klasse.</H1>
      <p>
        Der GLA komplettiert das umfangreiche SUV-Angebot von Mercedes-Benz. Er
        ist ein Allrounder mit den typischen Mercedes SUV-Genen, aber
        jugendlicher, voll Spannung. Bei seinem Debüt avancierte der GLA als
        erstes Kompakt-SUV von Mercedes-Benz aus dem Stand zu einem
        Erfolgsmodell in seinem Segment. Er wird charakterisiert durch seine
        sportlich-dynamische Formensprache und sein umfangreiches
        Individualisierungs-Angebot.
      </p>
      <ButtonLink to="/probefahrt/">weiter zur Terminauswahl</ButtonLink>
    </Section>
    <GallerySection
      InterieurImageSrc={InterieurImage}
      ExterieurImageSrc={ExterieurImage}
      GalleryImagesInterieur={GalleryImagesInterieurSrc}
      GalleryImagesExterieur={GalleryImagesExterieurSrc}
    />
    <Section align="default" variant="grey">
      <H1>Viele Optionen für Ihr ganz persönliches GLA Erlebnis.</H1>
      <SimpleSlider>
        <ImageBox
          imagesrc={DynamicSelect}
          title="DYNAMIC SELECT"
          headline="DYNAMIC SELECT"
          description="Eine komfortable, sportliche, besonders sportliche oder ganz individuelle Einstellung des Fahrzeugs lässt sich mit dem AMG DYNAMIC SELECT Controller in der Mittelkonsole abrufen. Mit dem gewählten Fahrprogramm ändern sich Parameter wie die Charakteristik von Motor, Getriebe und Lenkung."
        />
        <ImageBox
          imagesrc={Lowering}
          title="Fahrwerk mit 15 Millimeter Tieferlegung"
          headline="Fahrwerk mit 15 Millimeter Tieferlegung"
          description="Das Fahrwerk mit Tieferlegung vereint Agilität und Fahrkomfort, indem sich die Stoßdämpfer der Fahrsituation und dem Fahrbahnzustand automatisch anpassen. Federn und Dämpfer sind sportlich abgestimmt und das Fahrzeugniveau ist um 15 Millimeter abgesenkt."
        />
        <ImageBox
          imagesrc={SteeringSupport}
          title="Lenkunterstützung"
          headline="Geschwindigkeitsabhängige Lenkunterstützung"
          description="Bei der Parameterlenkung handelt es sich um eine geschwindigkeitsabhängige Lenkunterstützung. Bei hohen Geschwindigkeiten nimmt die Lenkkraftunterstützung ab, bei niedrigeren Geschwindigkeiten wird sie kontinuierlich gesteigert."
        />
      </SimpleSlider>
    </Section>
    <TestDrive />
  </Layout>
)

export default GLASuv
